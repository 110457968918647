import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import moment from "moment";
import { logos } from "../../assets/js/components/logos64bit";

export function toPdf(
  headers = [],
  data = [],
  fileName,
  options = {},
  spreadHeaders = false
) {
  const doc = new jsPDF({
    orientation: "l",
    ...options,
  });

  const currentHeaders = spreadHeaders ? [...headers] : [headers];

  autoTable(doc, {
    theme: "grid",
    head: currentHeaders,
    body: [...data],
  });

  doc.save(fileName);
}

export function formatHeaderFromExcelColumn(headers) {
  return headers.map((data) => {
    return data.label;
  });
}

export function pdfWithHeader(headers, code, nickname, data, fileName, title) {
  moment.locale("id");

  const totalPagesExp = "{total_pages_count_string}";

  const doc = new jsPDF("landscape");

  const today = new Date();

  const logo = logos;

  doc.setFontSize(8);

  const head = [headers];

  const body = [];

  data.map((c) => {
    const propertyValues = Object.values(c);

    body.push(propertyValues);
  });

  doc.autoTable({
    headStyles: {
      halign: "center",
      valign: "middle",
      fontSize: 8,
    },
    bodyStyles: {
      fontSize: 8,
    },
    head: head,
    body: body,
    columnStyles: {
      0: { columnWidth: 25 },
      1: { columnWidth: 30 },
      2: { columnWidth: 20 },
      3: { columnWidth: 15 },
      4: { columnWidth: 15 },
      5: { columnWidth: 20 },
      6: { columnWidth: 15 },
      7: { columnWidth: 15 },
      8: { columnWidth: 15 },
      9: { columnWidth: 25 },
      10: { columnWidth: 25 },
      11: { columnWidth: 20 },
      12: { columnWidth: 20 },
      13: { columnWidth: 20 },
    },
    didDrawPage: function(data) {
      // Header
      doc.setFontSize(20);
      doc.setTextColor(40);
      doc.addImage(logo, "png", data.settings.margin.left, 10, 25, 15);
      doc.text(title, data.settings.margin.left + 80, 25);
      doc.setFontSize(10);

      doc.text(
        `Hari/Tanggal : ${moment(today).format("dddd")}/ ${moment(today).format(
          "ll"
        )}`,
        data.settings.margin.left + 200,
        25
      );
      doc.text(
        `Waktu :  ${moment(today).format("LTS")}`,
        data.settings.margin.left + 200,
        30
      );
      doc.text(
        `Project Name: ${nickname}`,
        data.settings.margin.left + 200,
        35
      );
      doc.text(`Project Code: ${code}`, data.settings.margin.left + 200, 40);

      // Footer
      let str = "Page " + doc.internal.getNumberOfPages();
      // Total page number plugin only available in jspdf v1.0+
      if (typeof doc.putTotalPages === "function") {
        str = str + " of " + totalPagesExp;
      }
      doc.setFontSize(10);

      // jsPDF 1.4+ uses getWidth, <1.4 uses .width
      const pageSize = doc.internal.pageSize;
      const pageHeight = pageSize.height
        ? pageSize.height
        : pageSize.getHeight();
      var footer_lrs = 'PT. LEN RAILWAY SYSTEMS';
      doc.text(str, 270, pageHeight - 10);
      doc.text(footer_lrs, data.settings.margin.left, pageHeight - 10);
    },
    margin: { top: 45 },
  });

  if (typeof doc.putTotalPages === "function") {
    doc.putTotalPages(totalPagesExp);
  }

  doc.save(fileName + ".pdf");
}
