<!-- eslint-disable no-undef -->
<template>
  <div>
    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <div class="row">
          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Project Code - Nickname</label>
            <b-form-select
              id="input-lg"
              value-field="nickname"
              text-field="nickname"
              size="lg"
              v-model="selectNickname"
              @change="getStation($event.code)"
            >
              <b-form-select-option
                v-for="(proj, i) in this.projectList"
                v-bind:key="i"
                :value="{ code: proj.code, text: proj.nickname }"
              >
                {{ proj.nickname }}
              </b-form-select-option>
            </b-form-select>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Project Name</label>

            <b-form-input
              v-model="filter.nickname"
              disabled
              size="lg"
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Type</label>

            <b-form-select
              v-model="filter.type"
              :options="options.types"
              size="lg"
            ></b-form-select>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Scope</label>

            <b-form-select
              v-model="filter.scope"
              :options="formattedScopeOptions"
              size="lg"
            ></b-form-select>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Receiver</label>

            <b-form-select
              v-model="filter.receiver"
              :options="options.receiver"
              size="lg"
            ></b-form-select>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Status</label>

            <b-form-select
              v-model="filter.status"
              :options="formattedStatusOptions"
              size="lg"
            ></b-form-select>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Station</label>

            <b-form-select
              v-model="filter.station"
              :options="formattedStationOptions"
              size="lg"
            ></b-form-select>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Title</label>

            <b-form-input v-model="filter.title" size="lg"></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">PIC</label>

            <template v-if="currentIndex === i">
              <v-app>
                <v-autocomplete
                  v-model="filter.pic"
                  :items="formattedDrafterPicOptions"
                  @change="updatePicData(filter, i)"
                  :filter="customFilterPIC"
                  item-text="text"
                  small-chips
                  solo
                ></v-autocomplete>
              </v-app>
            </template>
          </div>
        </div>

        <div class="d-flex justify-content-end mt-5">
          <div class="d-flex">
            <button class="btn btn-primary btn-md mr-2" @click="getReports(0)">
              <i class="flaticon2-search-1"></i> Show
            </button>

            <button class="btn btn-warning btn-md" @click="getReports(1)">
              <i class="flaticon2-search-1"></i> Show Latest
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="card card-custom p-5 gutter-b">
      <div class="d-flex my-3 justify-content-end">
        <vue-excel-xlsx
          :data="formattedExcelData"
          :columns="columns"
          :file-name="
            `${filter.code} - ${filter.nickname} - Deliverable List Design`
          "
          :sheetname="'sheet1'"
        >
          <button class="btn btn-success btn-md mr-2" :disabled="!ableExport">
            <i class="fa fa-file-excel"></i>
            Excel
          </button>
        </vue-excel-xlsx>

        <button
          class="btn btn-danger btn-md mr-2"
          @click="exportPdf"
          :disabled="!ableExport"
        >
          <i class="fa fa-file-pdf"></i>
          PDF
        </button>
      </div>

      <div class="card-body p-0">
        <complete-table :loading="loading" :data="deliverableDesigns">
          <template #header>
            <tr class="text-left">
              <th style="min-width: 150px" class="pl-7">
                <span class="text-dark-75">Id</span>
              </th>
              <th style="min-width: 200px" class="pl-7">
                <span class="text-dark-75">Numbering</span>
              </th>
              <th style="min-width: 150px">Title</th>
              <th style="min-width: 150px">Station</th>
              <th style="min-width: 150px">Scope</th>
              <th style="min-width: 150px">Outstanding</th>
              <th style="min-width: 150px">Type</th>
              <th style="min-width: 150px">Date</th>
              <th style="min-width: 150px">Status</th>
              <th style="min-width: 150px">Revision</th>
              <th style="min-width: 150px">Version</th>
              <th style="min-width: 150px">Plan Date</th>
              <th style="min-width: 150px">PIC Drafter</th>
              <th style="min-width: 150px">PIC Designer</th>
              <th style="min-width: 150px">PIC Checker</th>
              <th style="min-width: 150px">Logistic</th>
              <th style="min-width: 150px">SHD External</th>
              <th style="min-width: 150px">Asbuilt</th>
              <th style="min-width: 200px">Change Management</th>
            </tr>
          </template>

          <template #defaultBody="{ item: deliverableDesign, i }">
            <tr v-bind:key="i" class="border-0">
              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ deliverableDesign.id }}
                </span>
              </td>
              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ deliverableDesign.numbering }}
                </span>
              </td>

              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 text-nowrap font-weight-bolder d-block font-size-lg"
                >
                  {{ deliverableDesign.title }}
                </span>
              </td>

              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{
                    deliverableDesign.station
                      ? deliverableDesign.station.name
                      : "-"
                  }}
                </span>
              </td>

              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{
                    deliverableDesign.scope ? deliverableDesign.scope.name : "-"
                  }}
                </span>
              </td>

              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{
                    deliverableDesign.outstanding
                      ? deliverableDesign.outstanding.name
                      : "-"
                  }}
                </span>
              </td>

              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{
                    deliverableDesign.type ? deliverableDesign.type.name : "-"
                  }}
                </span>
              </td>

              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{
                    moment(deliverableDesign.date_receive).format("DD-MM-YYYY")
                  }}
                </span>
              </td>

              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{
                    deliverableDesign.last_status
                      ? deliverableDesign.last_status.code
                      : ""
                  }}
                </span>
              </td>

              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ deliverableDesign.revision_version }}
                </span>
              </td>

              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ deliverableDesign.version }}
                </span>
              </td>

              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{
                    moment(deliverableDesign.planning_date).format("DD-MM-YYYY")
                  }}
                </span>
              </td>

              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{
                    deliverableDesign.drafter_pic
                      ? deliverableDesign.drafter_pic.name
                      : "-"
                  }}
                </span>
              </td>

              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{
                    deliverableDesign.designer_pic
                      ? deliverableDesign.designer_pic.name
                      : "-"
                  }}
                </span>
              </td>

              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{
                    deliverableDesign.checker_pic
                      ? deliverableDesign.checker_pic.name
                      : "-"
                  }}
                </span>
              </td>

              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{
                    moment(deliverableDesign.date_dist_logistic).format(
                      "DD-MM-YYYY"
                    )
                  }}
                </span>
              </td>

              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{
                    moment(
                      deliverableDesign.eksternal_distribution_date
                    ).format("DD-MM-YYYY")
                  }}
                </span>
              </td>

              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{
                    moment(deliverableDesign.asbuilt_distribution_date).format(
                      "DD-MM-YYYY"
                    )
                  }}
                </span>
              </td>

              <td class="pl-0 pt-8">
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  <button
                    class="btn btn-default"
                    @click="openChangeManagementModal(deliverableDesign)"
                  >
                    <span class="flaticon-eye"></span>
                  </button>
                </span>
              </td>
            </tr>
          </template>
        </complete-table>
      </div>
    </div>
    <v-dialog
      v-model="changeManagementModal"
      transition="dialog-top-transition"
      max-width="1000"
    >
      <v-card>
        <v-card-title class="headline">Change Management</v-card-title>

        <v-card-text>
          <complete-table
            :loading="changeManagementLoading"
            :data="changeManagements"
          >
            <template #header>
              <tr class="text-left">
                <th style="min-width: 250px" class="pl-7">
                  <span class="text-dark-75">Id</span>
                </th>
                <th style="min-width: 250px" class="pl-7">
                  <span class="text-dark-75">CR Number</span>
                </th>
                <th style="min-width: 300px">Title</th>
                <th style="min-width: 200px">CR Date</th>
                <th style="min-width: 100px">Type</th>
                <th style="min-width: 100px">Plan</th>
              </tr>
            </template>

            <template #defaultBody="{ item, i }">
              <tr v-bind:key="i">
                <td class="pl-0">
                  <span
                    class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.id }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.cr_number }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.title }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ moment(item.date).format("DD-MM-YYYY") }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.type ? item.type.code : "-" }}
                  </span>
                </td>

                <td>
                  <span
                    class="text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ item.plan_date }}
                  </span>
                </td>
              </tr>
            </template>
          </complete-table>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="changeManagementModal = false"
          >
            Tutup
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style>
.v-application--wrap {
  min-height: 10vh !important;
}
</style>

<script>
import { SET_BREADCRUMB } from "../../../core/services/store/breadcrumbs.module";
import CompleteTable from "../../content/widgets/advance-table/CompleteTable";
import { mapState } from "vuex";
import { arrayMoreThanOne } from "../../../core/helper/array-validation.helper";
import { GET_PROJECT_DETAIL } from "../../../core/services/store/project.module";
import { GET_SCOPES } from "../../../core/services/store/scope.module";
import {
  GET_DELIVERABLE_DESIGN,
  GET_DL_DESIGN_STATUS,
  GET_DL_DESIGN_STATION,
  GET_DL_DESIGN_DRAFT_PIC,
} from "@/core/services/store/reporting-deliverable-design.module";
import { showToast } from "../../../core/helper/toast.helper";
import { GET_DIVISION } from "@/core/services/store/division.module";
import { pdfWithHeader } from "@/core/helper/pdf.helper";
import ApiService from "@/core/services/api.service";
import {
  SHOW_PROJECT_INTERFACE,
  SHOW_PROJECT_DESIGN,
  SHOW_PROJECT_PLANNER,
  SHOW_PROJECT_DRAFTER,
  SHOW_PROJECT_KOOR,
  SHOW_PROJECT_ENGINER,
  SHOW_PROJECT_MANAGER,
  SHOW_PROJECT_ADMIN,
} from "../../../core/services/store/dl.design.module";
import moment from "moment";

export default {
  components: { CompleteTable },
  props: {
    pics: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      changeManagements: [],
      changeManagementLoading: false,
      changeManagementModal: false,
      filter: {
        code: null,
        nickname: null,
        scope: null,
        type: null,
        receiver: null,
        status: null,
        station: null,
        title: null,
        pic: null,
      },
      selectNickname: {
        code: null,
        text: null,
      },
      options: {
        pic: [],
        types: [
          {
            value: null,
            text: "Choose tipe",
          },
          {
            value: "doc",
            text: "DOC",
          },
          {
            value: "drw",
            text: "DRAW",
          },
          {
            value: "for",
            text: "SOFTWARE",
          },
        ],
        receiver: [
          {
            value: null,
            text: "Choose Receiver",
          },
          {
            value: "yes",
            text: "YES",
          },
          {
            value: "no",
            text: "NO",
          },
        ],
      },
      columns: [
        { label: "Numbering", field: "numbering" },
        { label: "Title", field: "title" },
        { label: "Station", field: "station" },
        { label: "Scope", field: "scope" },
        { label: "Type", field: "type" },
        { label: "Date", field: "date" },
        { label: "Status", field: "status" },
        { label: "Revision", field: "revision" },
        { label: "Version", field: "version" },
        { label: "Plan Date", field: "plan_date" },
        { label: "Delay", field: "delay" },
        { label: "Logistic", field: "logistic" },
        { label: "SHD External", field: "shd_external" },
        { label: "Asbuilt", field: "as_built" },
      ],
    };
  },
  computed: {
    ...mapState({
      projects: (state) => state.projectDetails.projectDetails?.data,
      scopes: (state) => state.scope.scopes?.data,
      deliverableDesigns: (state) =>
        state.deliverableDesign.deliverableDesigns?.data,
      deliverableDesignStatus: (state) =>
        state.deliverableDesign.deliverableDesignStatus?.data,
      deliverableDesignDraftPic: (state) =>
        state.deliverableDesign.deliverableDesignDraftPic?.data,
      deliverableDesignStation: (state) =>
        state.deliverableDesign.deliverableDesignStation?.data?.stations,
      loading: (state) => state.deliverableDesign.loading,
      divisions: (state) => state.division.divisions?.data,
    }),

    currentNickname() {
      return this.selectNickname.code;
    },

    formattedProjectOptions() {
      let projects = [];

      if (arrayMoreThanOne(this.projects)) {
        projects = this.projects.map((project) => {
          return {
            value: project.code,
            text: project.code + " - " + project.nickname,
          };
        });
      }

      return [{ value: null, text: "Choose Nickname" }, ...projects];
    },

    formattedStatusOptions() {
      let status = [];

      if (arrayMoreThanOne(this.deliverableDesignStatus)) {
        status = this.deliverableDesignStatus.map((status) => {
          return {
            value: status.code,
            text: status.name,
          };
        });
      }

      return [{ value: null, text: "Choose Status" }, ...status];
    },

    formattedStationOptions() {
      let station = [];

      if (arrayMoreThanOne(this.deliverableDesignStation)) {
        station = this.deliverableDesignStation.map((station) => {
          return {
            value: station.code,
            text: station.name,
          };
        });
      }

      return [{ value: null, text: "Choose Station" }, ...station];
    },

    formattedScopeOptions() {
      let scopes = [];

      if (arrayMoreThanOne(this.scopes)) {
        scopes = this.scopes.map((scope) => {
          return {
            value: scope.code,
            text: scope.name,
          };
        });
      }

      return [{ value: null, text: "Choose Scope" }, ...scopes];
    },

    formattedDrafterPicOptions() {
      let drafter_pic = [];

      if (arrayMoreThanOne(this.deliverableDesignDraftPic)) {
        drafter_pic = this.deliverableDesignDraftPic.map((drafter_pic) => {
          return {
            value: drafter_pic.code,
            name: drafter_pic.name,
            text: drafter_pic.code + " - " + drafter_pic.name,
          };
        });
      }
      return [...drafter_pic];
    },

    formattedDivisionOptions() {
      let divisions = [];

      if (arrayMoreThanOne(this.divisions)) {
        divisions = this.divisions.map((division) => {
          return {
            value: division.code,
            text: division.name,
          };
        });
      }

      return [{ value: null, text: "Choose Division" }, ...divisions];
    },

    ableExport() {
      return this.deliverableDesigns?.length > 0;
    },
    projectList() {
      let projectInterface = this.$store.getters.getterProjectInterface;
      let projectDesign = this.$store.getters.getterProjectDesign;
      let projectPlanner = this.$store.getters.getterProjectPlanner;
      let projectDrafter = this.$store.getters.getterProjectDrafter;
      let projectKoor = this.$store.getters.getterProjectKoor;
      let projectEnginer = this.$store.getters.getterProjectEnginer;
      let projectManager = this.$store.getters.getterProjectManager;
      let projectAdmin = this.$store.getters.getterProjectAdmin;

      let combine1 = this.merge(projectInterface, projectDesign, "code");
      let combine2 = this.merge(combine1, projectPlanner, "code");
      let combine3 = this.merge(combine2, projectDrafter, "code");
      let combine4 = this.merge(combine3, projectKoor, "code");
      let combine5 = this.merge(combine4, projectEnginer, "code");
      let combine6 = this.merge(combine5, projectManager, "code");
      let combine7 = this.merge(combine6, projectAdmin, "code");

      return combine7;
    },

    formattedExcelData() {
      return this.deliverableDesigns?.map((item) => {
        return {
          numbering: item.numbering,
          title: item.title,
          station: item.station?.name,
          scope: item.scope?.name,
          type: item.type?.name,
          date: item.date_receive,
          status: item.last_status?.code,
          revision: item.revision_version,
          version: item.version,
          plan_date: item.planning_date,
          delay: item.delay,
          logistic: item.date_dist_logistic,
          shd_external: item.eksternal_distribution_date,
          as_built: item.asbuilt_distribution_date,
        };
      });
    },
  },
  methods: {
    merge(a, b, prop) {
      var reduced = a.filter(
        (aitem) => !b.find((bitem) => aitem[prop] === bitem[prop])
      );
      return reduced.concat(b);
    },

    customFilterPIC(item, queryText) {
      const textOne = item.text.toLowerCase();
      const textTwo = item.value.toLowerCase();
      const searchText = queryText.toLowerCase();
      return (
        textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
      );
    },

    updatePicData(drafter_pic, index) {
      this.pics[index].name = this.formattedDrafterPicOptions.find(
        (x) => x.value === this.drafter_pic.code
      ).name;
    },

    exportPdf() {
      const columns = [
        "Numbering",
        "Title",
        "Station",
        "Scope",
        "Type",
        "Date",
        "Status",
        "Revision",
        "Version",
        "Plan Date",
        "Delay",
        "Logistic",
        "SHD External",
        "Asbuilt",
      ];

      const data = this.deliverableDesigns?.map((item) => {
        return [
          item.numbering,
          item.title,
          item.station?.name,
          item.scope?.name,
          item.type?.name,
          item.date_receive
            ? moment(item.date_receive).format("DD-MM-YYYY")
            : "-",
          item.last_status?.code,
          item.revision_version,
          item.version,
          item.planning_date
            ? moment(item.planning_date).format("DD-MM-YYYY")
            : "-",
          item.delay,
          item.date_dist_logistic
            ? moment(item.date_dist_logistic).format("DD-MM-YYYY")
            : "-",
          item.eksternal_distribution_date
            ? moment(item.eksternal_distribution_date).format("DD-MM-YYYY")
            : "-",
          item.asbuilt_distribution_date
            ? moment(item.asbuilt_distribution_date).format("DD-MM-YYYY")
            : "-",
        ];
      });

      pdfWithHeader(
        columns,
        this.filter.code,
        this.filter.nickname,
        data,
        `${this.filter.code} - ${this.filter.nickname} - Deliverable List Design`,
        "Deliverable List Design"
      );
    },

    getProjects() {
      this.$store.dispatch(GET_PROJECT_DETAIL, { jobdesk: "design" });
    },

    getScopes() {
      if (!arrayMoreThanOne(this.scopes)) {
        this.$store.dispatch(GET_SCOPES, {});
      }
    },

    getStatus() {
      if (!arrayMoreThanOne(this.deliverableDesignStatus)) {
        this.$store.dispatch(GET_DL_DESIGN_STATUS, {});
      }
    },

    getDraftPic() {
      if (!arrayMoreThanOne(this.deliverableDesignDraftPic)) {
        this.$store.dispatch(GET_DL_DESIGN_DRAFT_PIC, {});
      }
    },

    getStation(projectId) {
      if (projectId) {
        this.$store.dispatch(GET_DL_DESIGN_STATION, { code: projectId });
      }
    },

    getReports(latest) {
      if (this.filter.code) {
        this.$store.dispatch(GET_DELIVERABLE_DESIGN, {
          params: {
            ...this.filter,
          },
          code: this.filter?.code,
          latest,
        });
      } else {
        showToast("Warning", "Please Choose Project First", "danger");
      }
    },

    setNickname(value) {
      const project = this.projects?.find((project) => project?.code === value);

      this.filter.nickname = project?.nickname;
    },

    openChangeManagementModal(item) {
      this.changeManagementLoading = true;
      ApiService.setHeader();
      ApiService.query(`project/change/management/${item.id}/dld`)
        .then((response) => {
          this.changeManagements = response.data.data;
          this.changeManagementLoading = false;
          this.changeManagementModal = true;
        })
        .catch(() => {
          this.changeManagementModal = false;
          this.changeManagementLoading = false;
          showToast("Info", "Belum ada perubahan pada design", "info");
        });
    },
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Report Deliverable Design" },
    ]);

    //this.getProjects();

    await this.$store.dispatch(SHOW_PROJECT_INTERFACE);
    await this.$store.dispatch(SHOW_PROJECT_DESIGN);
    await this.$store.dispatch(SHOW_PROJECT_PLANNER);
    await this.$store.dispatch(SHOW_PROJECT_DRAFTER);
    await this.$store.dispatch(SHOW_PROJECT_KOOR);
    await this.$store.dispatch(SHOW_PROJECT_ENGINER);
    await this.$store.dispatch(SHOW_PROJECT_MANAGER);
    await this.$store.dispatch(SHOW_PROJECT_ADMIN);

    this.getScopes();

    this.getStatus();

    this.getDraftPic();

    this.$store.dispatch(GET_DIVISION);

    console.log("deliverableDesigns", this.deliverableDesigns);
  },
  watch: {
    currentNickname(value) {
      if (!value) {
        Object.assign(this.filter, {
          code: null,
          nickname: null,
        });
        return;
      }

      const project = this.projectList?.find(
        (project) => project?.code === value
      );
      this.filter.code = value;
      this.filter.nickname = project?.nickname;
    },
  },
};
</script>
